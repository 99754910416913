// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "Q_hG";
export var chatgptCasesSection = "Q_hK";
export var chatgptDevExpertiseSection = "Q_hL";
export var chatgptIndustriesSection = "Q_hD";
export var chatgptPrimeSection = "Q_hB";
export var chatgptProcessSection = "Q_hH";
export var chatgptQuotesSection = "Q_hN";
export var chatgptServicesSection = "Q_hC";
export var chatgptTechStackSection = "Q_hJ";
export var chatgptTrustedSection = "Q_hF";
export var chatgptWhyChooseSection = "Q_hM";